import React from "react";
import * as SH from "@shared";
import * as S from "./Press.styles";
import { Col, Row, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { TStrapiBlog } from "../ArticlePage.types";

export const Press: React.FC<TStrapiBlog> = ({ strapiBlog }) => {
  const { content, base } = strapiBlog;

  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" lg="6" xl={{ span: 5, offset: 1 }}>
            <SH.Text.Header2>
              {base.category.nameCategory.toLowerCase()}
            </SH.Text.Header2>
            <SH.Wrapper.WrapperWysiwyg className="pt-5">
              {ReactHtmlParser(content[0].post)}
            </SH.Wrapper.WrapperWysiwyg>
          </Col>
          <Col
            xs="12"
            lg="6"
            xl={{ span: 5, offset: 1 }}
            className="text-center"
          >
            <S.Image
              alt={content[0].image.alternativeText}
              src={content[0].image.localFile.publicUrl}
            />
          </Col>
          {content[0].urlPresentation && (
            <Col xs="12" xl="10">
              <S.WrapperPresentation>
                <SH.Text.Header2 className="text-center py-3 py-lg-5">
                  {content[0].titleVideo}
                </SH.Text.Header2>
                <iframe
                  width="100%"
                  height="100%"
                  src={content[0].urlPresentation}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                ></iframe>
              </S.WrapperPresentation>
            </Col>
          )}
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};

const heightImg = {
  mobile: 300,
  desktop: 590,
};
