import React from "react";
import * as SH from "@shared";
import { Col, Row, Container } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { TStrapiBlog } from "./ArticlePage.types";

export const Simple: React.FC<TStrapiBlog> = ({ strapiBlog }) => {
  const { title, content, base } = strapiBlog;
  return (
    <SH.Wrapper.Primary>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" lg="10" xl="8" className="text-center">
            <SH.Text.SubTitle1 colorComponent="darkblue">
              {base.category.nameCategory.toLowerCase()}
            </SH.Text.SubTitle1>
            <SH.Text.SubTitle2>{title}</SH.Text.SubTitle2>
          </Col>
          <Col xs="12" lg="10" xl="8">
            <SH.Wrapper.WrapperWysiwyg>
              {ReactHtmlParser(content[0].post)}
            </SH.Wrapper.WrapperWysiwyg>
          </Col>
        </Row>
      </Container>
    </SH.Wrapper.Primary>
  );
};
