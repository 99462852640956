import styled from "styled-components";
import * as SH from "@shared";
import * as theme from "@theme";

export const Header = styled.h1`
  ${SH.Text.Header2Css};
`;

export const WrapperHeroSection = styled(SH.Wrapper.HeroSection)`
  padding-top: 100px;
  @media ${theme.breakpoints.desktop} {
    padding-top: 0;
  }
`;
