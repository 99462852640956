import React from "react";
import * as SH from "@shared";
import { Row, Container, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useFetchDefaultHeroImg } from "@hooks";
import * as S from "./HeroSection.styles";

export const HeroSection = ({
  title,
  categoryName,
  publishDate,
  bgHero,
}: IHeroNewsProps) => {
  const { data } = useFetchDefaultHeroImg();
  const { defaultHero } = data.strapiGlobal;
  const fullHeroImg = { ...defaultHero, ...bgHero };

  return (
    <S.WrapperHeroSection
      className="d-flex align-items-center"
      bgMobile={fullHeroImg.bgMobile.localFile?.publicUrl}
      bgDesktop={fullHeroImg.bgDesktop.localFile?.publicUrl}
    >
      <Container>
        <Row className="mx-0 justify-content-center">
          <Col xs="10" lg="5" xl="4" className="px-xl-0 text-center">
            <SH.Text.SubTitle1>{categoryName}</SH.Text.SubTitle1>
            <SH.Text.SubTitle3 colorComponent="lightblue">
              {publishDate}
            </SH.Text.SubTitle3>
            <S.Header>{title}</S.Header>
          </Col>
        </Row>
      </Container>
    </S.WrapperHeroSection>
  );
};

type IHeroNewsProps = {
  title: string;
  publishDate: string;
  categoryName: string;
  bgHero?:
    | {}
    | {
        bgMobile: {
          localFile: {
            publicUrl: string;
          };
        };
        bgDesktop: {
          localFile: {
            publicUrl: string;
          };
        };
      };
};
