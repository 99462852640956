import styled from "styled-components";
import * as theme from "@theme";
import * as SH from "@shared";

export const WrapperPresentation = styled.div`
  background: ${theme.colors.whitegray};
  width: 100%;
  height: 400px;
  margin-top: 85px;
  padding: 20px;
  padding-bottom: 80px;
  overflow: hidden;
  @media ${theme.breakpoints.desktop} {
    height: 730px;
    padding-bottom: 140px;
  }
`;

export const Image = styled.img`
  height: 300px;
  @media ${theme.breakpoints.desktop} {
    border: 20px solid ${theme.colors.whitegray};
    border-right: 300px solid ${theme.colors.whitegray};
    height: 590px;
  }
`;
