import React from "react";
import { graphql } from "gatsby";
import Layout from "@components/Layout";
import * as C from "@components/ArticlePage";

const NewsBlog: React.FC<TData> = ({ data }) => {
  const article = data.strapiBlog;
  const articleType = data.strapiBlog.content[0].strapi_component;

  return (
    <Layout seo={article.seo}>
      <C.HeroSection
        title={article.title}
        categoryName={article.base.category.nameCategory}
        bgHero={article.heroBlog}
        publishDate={article.base.publishDate}
      />
      {articleType === ArticleType.PRESS && <C.Press strapiBlog={article} />}
      {articleType === ArticleType.SIMPLE && <C.Simple strapiBlog={article} />}
    </Layout>
  );
};

export default NewsBlog;

export const query = graphql`
  query BlogQuery($slug: String!) {
    strapiBlog(slug: { eq: $slug }) {
      id
      slug
      title
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicUrl
          }
          alternativeText
        }
      }
      heroBlog {
        bgMobile {
          localFile {
            publicUrl
          }
        }
        bgDesktop {
          localFile {
            publicUrl
          }
        }
      }
      content {
        urlPresentation
        post
        titleVideo
        image {
          alternativeText
          localFile {
            publicUrl
          }
        }
        strapi_component
      }
      base {
        publishDate(formatString: "MMMM YYYY")
        category {
          nameCategory
        }
      }
    }
  }
`;

type TData = {
  data: C.TStrapiBlog;
};

enum ArticleType {
  PRESS = "blog.press",
  SIMPLE = "blog.simple",
}
